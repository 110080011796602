.contactbody-section {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    background: #D0F7F0;
    position: relative;
    z-index: 1;
}

.contactbody-section .container-fluid {
    /* min-height: 100vh; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
}

.contactbody-section .map-wrapper {
    width: 100%;
    padding: 100px 0px;
}

.contactbody-section .map-wrapper .h1 {
    color: #10606C;
    text-align: left;
    font-family: 'Helvetica';
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
}

.contactbody-section .map-wrapper img {
    width: 100%;
    margin-top: 50px;
}


.contactbody-section .content-box .subhead {
    color: #10606C;
    font-family: Inter;
    font-family: 'Helvetica';
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;

}


.contactbody-section .content-box .title {
    color: #10606C;
    text-align: left;
    font-family: Inter;
    font-family: 'Helvetica';
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0%;

}

.contactbody-section .content-box .switch-btns {
    display: flex;
    flex-direction: column;
    gap:20px;
    max-width: 320px;
}

.contactbody-section .content-box .switch-btns .btn-box {
    padding: 15px 0px;
    border-bottom: 1px solid #10606C;
    display: flex;
    gap: 10px;
    align-items: center;
}

.btn-box input[type="radio"]{
    background: transparent;
    appearance: none;
    width: 20px;
    height: 21px;
    border-radius: 20px;
    border: 2px solid #10606C;
}

input[type="radio"]:checked {
    background: url('../images/checked.png') no-repeat center center;
    border: none;
}


input[type="checkbox"].agree {
    background: transparent;
    appearance: none;
    width: 20px;
    height: 21px;
    border-radius: 20px;
    border: 2px solid #F11576;
}


.text-dark .btn-box {
    display: flex;
    gap: 10px;
    align-items: center;

    

}

.text-dark span.agreetbn {
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0%; 
    text-decoration-style: solid;
    color: #10606C;
}

.text-dark span.agreetbn a {
    text-decoration: underline !important; 
    color: #10606C;
}


input[type="checkbox"][value=true].agree  {
    background: url('../images/checked.png') no-repeat center center;
    border: none;
}
/* .h1.anima {
    line-height: 140px;
    padding-bottom: 20px;
} */

.contactbody-section .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.contactbody-section .form-success {
    color: #000;
}


.contactbody-section .content-box {
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle; */
    height: 100%;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
}

.contactbody-section .content-box .add-title {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    text-transform: capitalize;
    color: #05304D;
}

.contactbody-section .content-box h4 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    text-align: left;
    color: #000000;
}


.contactbody-section .content-box P {
    padding-top: 10px;
    padding-left: 10px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}


.close-btn {

    padding: 20px !important;
    color: #ffffff;
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 128.35%);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    text-align: right;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    border: 1px solid #FFF;
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 98.44%);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

}



.contactbody-section .content-box .h1 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 138px;
    text-transform: capitalize;
    color: #05304D;
    z-index: 1;
    font-size: 80px !important;
    line-height: 100px !important;

}


/* form */

.contactbody-section .form-box label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #03AD9B;
    color: #000000;
}

.PhoneInputCountrySelect + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: #03b2cb;
}

.contactbody-section .form-box input[type=text],
.contactbody-section .form-box input[type=email],
.contactbody-section .form-box input[type=tel],
.contactbody-section .form-box textarea,
.contactbody-section .form-box select {
    width: 100%;
    padding: 12px 20px 12px 0px;
    margin: 8px 0;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    border:none;
    border-bottom: 1px solid #10606C;
    border-radius: 0px;
    color: #10606C;
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0%;
}

.contactbody-section .form-box input[type=text]:focus,
.contactbody-section .form-box input[type=email]:focus,
.contactbody-section .form-box input[type=tel]:focus,
.contactbody-section .form-box textarea:focus,
.contactbody-section .form-box select:focus {
    box-shadow: none;
    outline: none;
}

.contactbody-section .country-box {
    position: relative;
}

.contactbody-section .country-box::after {
    position: absolute;
    right: 18px;
    top: 35%;
    content: "";
    background: url('../images/keyboard_arrow_down.png') no-repeat center center;
    z-index: 3;
    width: 20px;
    height: 10px;
    transform: translateY(-50%);
}

.contactbody-section .country-box.select::after {
    transform: translateY(-50%) rotate(180deg);
}

.contactbody-section .form-box input[type=text],
.contactbody-section .form-box input[type=email],
.contactbody-section .form-box input[type=tel],
.contactbody-section .form-box select {
    height: 50px;
}




.contactbody-section .form-box textarea {
    height: 150px;
}


::placeholder {
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0%;
    color: #10606C;

}


.contactbody-section .form-box span.agreebtn {
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0%;
    color: #10606C;
}

.contactbody-section .form-box span.agreebtn a {
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0%;
    color: #10606C;
   text-decoration: underline !important;
}


.contactbody-section .form-box p.agreetext {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0%;
    color: #10606C;
    padding-left: 0px;
}


.contactbody-section .form-box input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
}

.contactbody-section .form-box input[type=submit]:hover {
    background-color: #45a049;
}

.contactbody-section .form-box button {
    padding: 20px 45px;
    color: #ffffff;
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 128.35%);
    background: #01C6B2;

    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    text-align: right;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    border: 1px solid #FFF;
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 98.44%);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}


.contactbody-section .form-box button {
    padding: 12px 20px;
    color: #ffffff;
    background: #01C6B2;

    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0%;

    text-align: center;
    border-radius: 50px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: none;
}  


/* .contactbody-section .form-box input[type="checkbox"]:after {
    content: "✔";
    content: '\2713';
    display: inline-block;
    color: #000;
    padding: 0 6 0 0;
    font-weight: 900;
    position: absolute;
    top: -5px;
    width: 100%;
    height: 100%;
} */


input[type="checkbox"][value=true]:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;

      content: "✔";
    content: '\2713';
    display: inline-block;
    display: none;
    color: #000;
    padding: 0 6 0 0;
    font-weight: 900;
    position: absolute;
    top: -5px;
    width: 100%;
    height: 100%;
}

.contactbody-section .form-box .checkbox-label{
    color: #000;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.contactbody-section .content-box .errormsg p{
    color: red !important;
    font-weight: 600;
}
.contactbody-section .content-box .address-para,.contactbody-section .content-box .tellink{
    color: white;
    color: #000;
    }
    .contactbody-section .content-box a svg{
    fill: white;
    fill: transparent;
    margin-right: 20px;
    }

 .callbtn {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.50);
    color: #FFF;
    font-family: Helvetica;
    margin-top: 10px;
    padding: 20px;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
 }  
 
 

 
/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .contactbody-section {
        background-image: none;
    }

    .contactbody-section .content-box {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contactbody-section .map-wrapper {
        padding: 50px 0px;
    }

    .contactbody-section .col-md-3,
    .contactbody-section .col-md-8,
    .contactbody-section .col-md-6 {
        flex:unset
    }

    .contactbody-section .content-box .switch-btns {
        max-width: unset;    
    }



    .contactbody-section .content-box .errormsg p{
        color: red;
        font-weight: 500;
    }
    .contactbody-section {
        /* padding-bottom: -20px !important; */
        margin-bottom: -30px;
    }
    .contactbody-section .content-box .address-para,.contactbody-section .content-box .tellink{
        color: #000;
        font-size: 12px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        }
        .contactbody-section .content-box a svg{
        fill: transparent;
        }
    .contactbody-section .content-box .h1 {
        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal !important;
        text-align: center;
        position: relative;
        top: -5%;
    }
    /* .contactbody-section .content-box .h1.anima span.anima-underline:after {
        width: 600%;
    } */
    .contactbody-section .content-box .h1.anima span.anima-underline {
        /* color: #000;
        font-size: 50px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;
        left: -55px;
        top: -10px; */

        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-style: normal;
        text-align: left;
        font-weight: 700;
        line-height: normal !important;
        position: relative;
        left: -14px;
        top: -10px;
        width: 150px;

        left: 0px;
        top: 0px;
        width: unset;

    }


 
     .contactbody-section .form-box .checkbox-label{
        color: #000;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-left: -2px;
    }
    .contactbody-section .content-box .strok {
        width: fit-content;
        text-align: center;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-size: 60px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal !important;
        text-transform: capitalize;
        position: absolute;
        left: 70px;
        top: 47px;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);

    }

  
    .callbtn {
        font-size: 18px;
    }

    /* form */


    .contactbody-section .content-box h4 {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contactbody-section .form-box label {
        color: #03AD9B;
        color: #000000;
        font-size: 12px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    /* .contactbody-section .form-box input[type=text],
.contactbody-section .form-box input[type=email],
.contactbody-section .form-box textarea,
.contactbody-section .form-box select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #03AD9B;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #03AD9B;
    border-radius: 10px;
} */


.contactbody-section .country-box {
    position: relative;
}

/* .contactform-section select {
    position: relative;
} */

/* .contactbody-section .country-box::after {
    position: absolute;
    right: 18px;
    top: 60px;
    content: "";
    z-index: 3;
    width: 0px;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 9px solid #ccc;
    transform: translateY(-50%) rotate(90deg);
}

.contactbody-section .country-box.select::after {
    transform: translateY(-50%) rotate(-90deg);
} */
    
.contactbody-section .form-box input[type=text],
.contactbody-section .form-box input[type=email],
.contactbody-section .form-box select {
    height: 50px;
}

    .contactbody-section .form-box textarea {
        height: 115px;
    }

    /* .contactbody-section .content-box .h1.anima span.anima-underline:after {
        padding-top: 10px;
    }  */
    .contactbody-section .content-box .form-box P {
        color: #000;
        font-size: 12px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .contactbody-section .form-box input[type="checkbox"] {
        color: #000;
        font-size: 12px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    /* .contactbody-section .form-box .radiobtn {
        color: #000;
        font-size: 12px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    } */

    .contactbody-section .form-box button {
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        width: 180px;
        height: 48px;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        margin-top: 30px;
    }

    .contactbody-section .form-box .mobile-align {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* .contactbody-section .content-box P .margnbtm{
margin-bottom: 10px !important;
padding-bottom: 10px !important;
} */


/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {

    .contactbody-section .col-md-3,
    .contactbody-section .col-md-8,
    .contactbody-section .col-md-6 {
        flex:unset
    }

    .contactbody-section .content-box .switch-btns {
        max-width: unset;    
    }

    .contactbody-section {
        background-image: none;
    }
    .contactbody-section .content-box .errormsg p{
        color: red;
        font-weight: 500;
    }
    .contactbody-section {
        /* padding-bottom: -20px !important; */
        margin-bottom: -30px;
    }
    .contactbody-section .content-box .address-para,.contactbody-section .content-box .tellink{
        color:  #000;
        font-size: 12px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        }
        .contactbody-section .content-box a svg{
        fill: transparent;
        }
    .contactbody-section .col-md-6 {
        flex:unset
    }

    .contactbody-section .content-box .h1 {
        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal !important;
        text-align: center;
        position: relative;
        top: -5%;
    }
    .contactbody-section .form-box .checkbox-label{
        color: #000;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-left: -2px;
    }

    /* form */

    .contactbody-section .content-box h4 {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contactbody-section .form-box label {
        color: #03AD9B;
        color: #000000;
        font-size: 12px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    /* .contactbody-section .form-box input[type=text],
.contactbody-section .form-box input[type=email],
.contactbody-section .form-box textarea,
.contactbody-section .form-box select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #03AD9B;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #03AD9B;
    border-radius: 10px;
} */

    .contactbody-section .form-box input[type=text],
    .contactbody-section .form-box input[type=email],
    .contactbody-section .form-box select {
        height: 50px;
    }

    .contactbody-section .form-box textarea {
        height: 115px;
    }

    /* .contactbody-section .content-box .h1.anima span.anima-underline:after {
        padding-top: 10px;
    }  */
    .contactbody-section .content-box .form-box P {
        color: #000;
        font-size: 12px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .contactbody-section .form-box input[type="checkbox"] {
        color: #000;
        font-size: 12px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .contactbody-section .form-box button {
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        width: 180px;
        height: 48px;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        margin-top: 30px;
    }

    .contactbody-section .form-box .mobile-align {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .contactbody-section {
        background-image: none;
    }

    .contactbody-section .col-md-6 {
        max-width: 720px;
        width: 100%;
    }

    .contactbody-section .content-box .switch-btns {
        max-width: unset;    
    }

    .contactbody-section .content-box .errormsg p{
        color: red;
        font-weight: 500;
    }
    .contactbody-section {
        /* padding-bottom: -20px !important; */
        margin-bottom: -30px;
    }
    .contactbody-section .content-box .address-para,.contactbody-section .content-box .tellink{
        color:  #000;
        font-size: 12px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        }
        .contactbody-section .content-box a svg{
        fill: transparent;
        }
    .contactbody-section .col-md-6 {
        flex:unset
    }

    .contactbody-section .content-box .h1 {
        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal !important;
        text-align: center;
        position: relative;
        top: -20px;
    }
    .contactbody-section .form-box .checkbox-label{
        color: #000;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-left: -2px;
    }

    .contactbody-section .content-box .h1.anima span.anima-underline {
        color: #000;
        font-size: 50px !important;
        font-family: Helvetica;
        font-style: normal;
        text-align: left;
        font-weight: 700;
        line-height: normal !important;
        position: relative;
        left: -14px;
        top: -10px;
        width: 150px;
        left: 0px;
        top: 0px;
        width: unset;
     }

     .contactbody-section .content-box .h1.anima span.anima-underline:after {
        top:10px
    }

    .contactbody-section .content-box .strok {
        width: fit-content;
        text-align: center;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px rgba(132, 207, 200, 0.7);
        font-size: 60px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal !important;
        text-transform: capitalize;
        position: absolute;
        top: 47px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    .contactbody-section iframe {
        width: 95%;
        height: 300px;

    }

    .address-map {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    /* form */

    .contactbody-section .content-box h4 {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contactbody-section .form-box label {
        color: #03AD9B;
        color: #000000;
        font-size: 12px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    /* .contactbody-section .form-box input[type=text],
.contactbody-section .form-box input[type=email],
.contactbody-section .form-box textarea,
.contactbody-section .form-box select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #03AD9B;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #03AD9B;
    border-radius: 10px;
} */

    .contactbody-section .form-box input[type=text],
    .contactbody-section .form-box input[type=email],
    .contactbody-section .form-box select {
        height: 60px;
    }

    .contactbody-section .form-box textarea {
        height: 115px;
    }

    /* .contactbody-section .content-box .h1.anima span.anima-underline:after {
        padding-top: 10px;
    }  */
    .contactbody-section .content-box .form-box P {
        color: #000;
        font-size: 12px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .contactbody-section .form-box input[type="checkbox"] {
        color: #000;
        font-size: 12px !important;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .contactbody-section .form-box button {
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        width: 180px;
        height: 48px;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        margin-top: 30px;
    }

    .contactbody-section .form-box .mobile-align {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width: 1024px) and (max-width: 1280px) {
    .contactbody-section .container-fluid {
        height: 100%;
        padding: 7% 0px;
    }

    .contactbody-section .content-box {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .contactbody-section .content-box .strok {
        font-size: 112px;
        line-height: 126px;
        left: -50px;
        top: 40px;
        left: -35px;
        top: 0px;    
    }

    .contactbody-section .content-box .h1 {
        font-size: 84px;
        line-height: 96px;
    }

    .contactbody-section .content-box h4 {
        font-size: 28px;
        line-height: 32.2px;
    }

    .contactbody-section .form-box label {
        font-size: 14px;
        line-height: 130%;
    }

    .contactbody-section .content-box .address-para,
    .contactbody-section .content-box .tellink{
        color:#000000;
    }
    
    .contactbody-section .content-box a svg {
        fill: #000000;
    }

    .contactbody-section .form-box button {
        font-size: 13.5px;
        line-height: 15.75px;
    }

}



@media (min-width: 1280px) and (max-width: 1360px) {
    .contactbody-section .container-fluid {
        height: 100%;
        padding: 10% 0px;
    }

    .contactbody-section .content-box {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .contactbody-section .content-box .strok {
        font-size: 120px;
        line-height: 135px;
        left: -50px;
        top: 40px;
        left: -35px;
        top:50px;    
    
    }

    .contactbody-section .content-box .h1 {
        font-size: 90px;
        line-height: 103.5px;
    }

    .contactbody-section .content-box h4 {
        font-size: 30px;
        line-height: 34.5px;
    }

    .contactbody-section .form-box label {
        font-size: 15px;
        line-height: 130%;
    }

    .contactbody-section .content-box .address-para,
    .contactbody-section .content-box .tellink{
        color:#000000;
    }
    
    .contactbody-section .content-box a svg {
        fill: #000000;
    }

    .contactbody-section .form-box button {
        font-size: 14.4px;
        line-height: 16.8px;
    }

}




@media (min-width: 1360px) and (max-width: 1440px) {
    .contactbody-section .container-fluid {
        height: 100%;
        padding: 14% 0px;
    }
   
    .contactbody-section .content-box {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .contactbody-section .content-box .strok {
        font-size: 128px;
        line-height: 144px;
        left: -45px;
        top: 45px;
    }

    .contactbody-section .content-box .h1 {
        font-size: 96px;
        line-height: 110.4px;
    }

    .contactbody-section .content-box h4 {
        font-size: 32px;
        line-height: 36.8px;
    }

    .contactbody-section .form-box label {
        font-size: 16px;
        line-height: 130%;
    }

    .contactbody-section .content-box .address-para,
    .contactbody-section .content-box .tellink{
        color:#000000;
    }
    
    .contactbody-section .content-box a svg {
        fill: #000000;
    }

    .contactbody-section .form-box button {
        font-size: 15.3px;
        line-height: 17.85px;
    }

}

@media (min-width: 1440px) and (max-width: 1680px) {
    .contactbody-section .form-box button {
        font-size: 16.2px;
        line-height: 18.9px;
    }
}

@media only screen and (min-width: 1680px) and (max-width: 1919px) {

    .contactbody-section .container-fluid {
        height: 100%;
        padding: 14% 0px;
    }
   
    .contactbody-section .content-box {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .contactbody-section .content-box .strok {
        font-size: 136px;
        line-height: 153px;
        left: -50px;
        top: 40px;
        left: -35px;
        top:45px;    

    }

    .contactbody-section .content-box .h1 {
        font-size: 102px;
        line-height: 117.3px;
    }

    .contactbody-section .content-box h4 {
        font-size: 34px;
        line-height: 30.1px;
    }

    .contactbody-section .form-box label {
        font-size: 17px;
        line-height: 130%;
    }

    .contactbody-section .content-box .address-para,
    .contactbody-section .content-box .tellink{
        color:#000000;
    }
    
    .contactbody-section .content-box a svg {
        fill: #000000;
    }

    .contactbody-section .form-box button {
        font-size: 17.1px;
        line-height: 19.95px;
    }

}

@media only screen and (min-width: 1920px) {}